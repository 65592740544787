/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC } from "react";
import { Link } from "react-router-dom";
import {
  KTSVG,
  toAbsoluteUrl,
  defaultAlerts,
  defaultLogs,
} from "../../../helpers";

const HeaderNotificationsMenu: FC<React.PropsWithChildren<unknown>> = () => (
  <div
    className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
    data-kt-menu="true"
  >
    <div className="d-flex flex-column bgi-no-repeat rounded-top">
      <div
        className="h-100px"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/misc/pattern-1.jpg"
          )}')`,
        }}
      >
        <h3 className="text-white fw-bold px-9 mt-10 mb-6">
          Notifications <span className="fs-8 opacity-75 ps-3">3 New</span>
        </h3>
      </div>
      <div className="tab-content">
        <div className="scroll-y mh-325px my-5 px-8">
          {defaultAlerts.map((alert, index) => (
            <div key={`alert${index}`} className="d-flex flex-stack py-4">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-35px me-4">
                  <span
                    className={clsx("symbol-label", `bg-light-${alert.state}`)}
                  >
                    {" "}
                    <KTSVG
                      path={`/media/${alert.icon}`}
                      className={`svg-icon-2 svg-icon-${alert.state}`}
                    />
                  </span>
                </div>

                <div className="mb-0 me-2">
                  <a
                    href="#"
                    className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                  >
                    {alert.title}
                  </a>
                  <div className="text-gray-400 fs-7">{alert.description}</div>
                </div>
              </div>

              <span className="badge badge-light fs-8">{alert.time}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export { HeaderNotificationsMenu };
