import React, { useState } from "react"
import { IPasswordDetails } from "../views/overview/OverviewModel"
import { useMutation } from "@tanstack/react-query"
import cogoToast from "cogo-toast"
import * as Yup from "yup"
import { Formik, Form, ErrorMessage } from "formik"
import { updatePassword, getAuth } from "../../../../apis/app.service"

const passwordDetailsSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Old password is required"),
  password: Yup.string().required("New Password is required"),
  passwordConfirm: Yup.string().required("Password confirm  is required")
})

const PasswordDetails: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [initialValues] = useState<IPasswordDetails>({
    oldPassword: "",
    password: "",
    passwordConfirm: ""
  })

  const mutation = useMutation({
    mutationFn: (body: IPasswordDetails) => updatePassword(body),
    onSuccess: ({ message, data }) => {
      cogoToast.success(message, { hideAfter: 2, position: "top-right" })

      setTimeout(() => {
        cogoToast.success("You need to log in again with new password", { hideAfter: 3, position: "top-right" })
      }, 2000)
      setTimeout(() => {
        getAuth({ type: "LOGOUT" })
      }, 4000)
    },
    onError(error, variables, context) {
      cogoToast.error(`${error}`, {
        hideAfter: 3,
        position: "top-right"
      })
    }
  })

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_password"
        aria-expanded="true"
        aria-controls="kt_account_password"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Update Password</h3>
        </div>
      </div>

      <div id="kt_account_password" className="collapse show">
        <Formik
          validationSchema={passwordDetailsSchema}
          initialValues={initialValues}
          onSubmit={async (values) => {
            mutation.mutate(values)
          }}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Old password</label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="oldPassword"
                      onChange={formik.handleChange}
                      value={formik.values.oldPassword}
                      placeholder="Old Password"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="oldPassword" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">New Password</label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      placeholder="New password"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span className="required">Confirm password</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Confirm password"
                      name="passwordConfirm"
                      onChange={formik.handleChange}
                      value={formik.values.passwordConfirm}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="passwordConfirm" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end py-6 px-9">
                <button type="submit" className="btn btn-primary" disabled={mutation.isLoading}>
                  {mutation.isLoading && (
                    <>
                      <div className="spinner-border spinner-border-sm mr-2" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </>
                  )}
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export { PasswordDetails }
