import React from "react"
import { ProfileDetails } from "../../cards/ProfileDetails"
import { PasswordDetails } from "../../cards/PasswordUpdate"

export function Overview() {
  return (
    <>
      <ProfileDetails />
      <PasswordDetails />
    </>
  )
}
