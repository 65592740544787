/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useRef } from "react"
import ReactGA from "react-ga4"
import cogoToast from "cogo-toast"
import moment from "moment"
import type, { DatePicker, Button, Dropdown, MenuProps } from "antd"
import { useQuery } from "@tanstack/react-query"

import { getCSS } from "../../assets/ts/_utils"
import { DeviceDatePicker } from "./components/datepicker/DeviceDatePicker"
import { toAbsoluteUrl } from "../../helpers"
import { TargetSatisfaction, OfficeComparison, PercentageDistButtonPresses } from "../../partials/widgets"
import "./core/style.css"
import { getDeviceStats, getFilters } from "../../apis/app.service"
import { LoadingSpinner } from "../shared_components/LoadingSpinner"
import { APICore } from "../../apis/api.core"
const { RangePicker } = DatePicker

const api = new APICore()

const DevicesPage = () => {
  ReactGA.initialize("G-7PJYYTMLJC")
  const [clientBody, setClientBody] = useState<string>("All")
  const [station, setStation] = useState<string>("All")
  const [device, setdevice] = useState<string>("All")
  const [type, setType] = useState<string>("week")
  const [selectedDateFormatted, setSelectedDateFormatted] = useState(moment().format("YYYY-Wo"))
  const [selectedDate, setSelectedDate] = useState<any>(moment())
  const [dateQuery, setDateQuery] = useState({
    currentStartDate: moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"),
    currentEndDate: moment().endOf("week").format("YYYY-MM-DD HH:mm:ss"),
    previousStartDate: moment().startOf("week").subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"),
    previousEndDate: moment().endOf("week").subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"),
    timeline: ["This week", "Last week"]
  })

  const chartRef = useRef<HTMLDivElement | null>(null)

  const userData = api.getLoggedInUser()
  const path = window.location.pathname + window.location.search

  const deviceFilterResponse = useQuery({
    queryKey: ["Device Filters"],
    queryFn: () => getFilters(),
    refetchOnWindowFocus: false,
    onError: (error) => {
      cogoToast.error(`${error}`, {
        hideAfter: 5,
        position: "top-right"
      })
    }
  })

  console.log(dateQuery)

  const deviceStatisticResponse = useQuery({
    queryKey: ["Device Statistics"],
    queryFn: () =>
      getDeviceStats({
        type: type,
        name: station,
        device,
        ...dateQuery
      }),
    refetchOnWindowFocus: false,
    onError: (error) => {
      cogoToast.error(`${error}`, {
        hideAfter: 5,
        position: "top-right"
      })
    }
  })

  const devicefilters = deviceFilterResponse?.data?.data
  const data = deviceStatisticResponse?.data?.data
  const refetchData = deviceStatisticResponse?.refetch

  useEffect(() => {
    refetchData()
  }, [device, type, selectedDate, station, clientBody, refetchData])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: path })
  }, [])
  useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, "height"))
    console.log(height)
  }, [chartRef])

  return (
    <>
      {/* begin::Body */}
      <div className="g-5 g-xl-8">
        <div className="toolbar" id="kt_toolbar">
          {/* begin::Container */}
          <div id="kt_toolbar_container">
            {/* begin::Actions */}
            <div className="d-flex align-items-end ps-8">
              {/* begin::Wrapper */}
              <div className="me-4">
                {/* begin::Menu */}
                {/* begin form body */}
                {deviceStatisticResponse?.isFetching ? null : ( // <SyncLoader size={10} color="#FCA42C" />
                  <form className="form" noValidate id="devices_data_filter">
                    <div className="w-100 d-flex flex-row justify-content-end">
                      {/* begin clientBody */}
                      {userData?.user?.role === "client" ? (
                        <div className=" me-5">
                          <select
                            className="form-select form-select-solid"
                            name="clientBody"
                            onChange={(e) => {
                              setClientBody(e.target.value)
                            }}
                          >
                            {devicefilters?.clientBodyList?.map((cb: any, index: number) => (
                              <option key={index} value={cb}>
                                {cb}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}
                      {/* end clientBody
                                    {/* begin station */}
                      {userData?.user?.role === "clientBody" ? (
                        <div className=" me-5">
                          <select
                            className="form-select form-select-solid"
                            name="station"
                            value={station}
                            onChange={(e) => {
                              setStation(e.target.value)
                            }}
                          >
                            {devicefilters?.stationList?.map((cb: any, index: number) => (
                              <option key={index} value={cb}>
                                {cb}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}

                      {userData?.user?.role === "station" ? (
                        <div className=" me-5">
                          <select
                            className="form-select form-select-solid"
                            name="device"
                            value={device}
                            onChange={(e) => {
                              setdevice(e.target.value)
                            }}
                          >
                            {devicefilters?.deviceList?.map((cb: any, index: number) => (
                              <option key={index} value={cb}>
                                {cb}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}
                      {/* end station */}
                      <DeviceDatePicker
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        selectedDateFormatted={selectedDateFormatted}
                        setSelectedDateFormatted={setSelectedDateFormatted}
                        type={type}
                        setType={setType}
                        setDateQuery={setDateQuery}
                      />
                      {/* begin period */}
                      {/* <div className="">
                        <select
                          className="form-select form-select-solid"
                          name="period"
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value)
                          }}
                        >
                          <option key={0} value="year">
                            Yearly
                          </option>

                          <option key={1} value="quater" selected>
                            Quaterly
                          </option>
                          <option key={2} value="month">
                            Monthly
                          </option>

                          <option key={3} value="week">
                            Weekly
                          </option>

                          <option key={4} value="day">
                            Daily
                          </option>

                          <option key={5} value="custom">
                            Custom
                          </option>
                          <option>
                            <RangePicker onClick={(e) => e.stopPropagation()} />
                          </option>
                        </select>
                      </div> */}
                      {/* end period */}
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          {/* end::Container */}
        </div>
        {/* end::ToolBar */}

        {deviceStatisticResponse?.isFetching ? (
          <LoadingSpinner />
        ) : (
          <>
            {/* begin satisafaction rating box */}
            <div className="dashboard-card card card-xl-stretch mb-5 mb-xl-8 rating-box-otr ">
              <div className="inner-header d-flex">
                <h4 className="title flex-1">Satisfaction Rating Box</h4>
                <span className="tol-revision">
                  <b>Total Responses : </b>
                  {data?.overallStatistics.totalCount}
                </span>
              </div>
              <div
                className="inner-body"
                //  style={{ minHeight: "90px" }}
              >
                <>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="inner-card flex-column">
                        <p className="title">{data?.overallStatistics?.timeline[0]}</p>
                        <p className="value current">{data?.overallStatistics?.currentAverageSatisfaction}%</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="inner-card flex-column">
                        <p className="title">{data?.overallStatistics?.timeline[1]}</p>
                        <p className="value last">{data?.overallStatistics?.previousAverageSatisfaction}%</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="inner-card align-items-center">
                        {data?.overallStatistics?.improved ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className="bi bi-arrow-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="48"
                            height="48"
                            fill="currentColor"
                            className="bi bi-arrow-down"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                            />
                          </svg>
                        )}

                        <p className="growth-value">{data?.overallStatistics?.difference}%</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="rating-container">
                        <div className="item-container" key={1}>
                          <div className="item">
                            <img src={toAbsoluteUrl("/media/icons/satisfaction/very-bad.png")} alt="Very Bad" />
                            <div className="count-detail">
                              <p className="count">{data?.overallStatistics?.individualPercent[0].total}</p>
                              <p className="percentage">{data?.overallStatistics?.individualPercent[0].percent}%</p>
                            </div>
                          </div>
                        </div>

                        <div className="item-container" key={2}>
                          <div className="item">
                            <img src={toAbsoluteUrl("/media/icons/satisfaction/bad.png")} alt="Bad" />
                            <div className="count-detail">
                              <p className="count">{data?.overallStatistics?.individualPercent[1].total}</p>
                              <p className="percentage">{data?.overallStatistics?.individualPercent[1].percent}%</p>
                            </div>
                          </div>
                        </div>

                        <div className="item-container" key={3}>
                          <div className="item">
                            <img src={toAbsoluteUrl("/media/icons/satisfaction/medium.png")} alt="Medium" />
                            <div className="count-detail">
                              <p className="count">{data?.overallStatistics?.individualPercent[2].total}</p>
                              <p className="percentage">{data?.overallStatistics?.individualPercent[2].percent}%</p>
                            </div>
                          </div>
                        </div>

                        <div className="item-container" key={4}>
                          <div className="item">
                            <img src={toAbsoluteUrl("/media/icons/satisfaction/good.png")} alt="Good" />
                            <div className="count-detail">
                              <p className="count">{data?.overallStatistics?.individualPercent[3].total}</p>
                              <p className="percentage">{data?.overallStatistics?.individualPercent[3].percent}%</p>
                            </div>
                          </div>
                        </div>

                        <div className="item-container" key={5}>
                          <div className="item">
                            <img src={toAbsoluteUrl("/media/icons/satisfaction/very-good.png")} alt="Very Good" />
                            <div className="count-detail">
                              <p className="count">{data?.overallStatistics?.individualPercent[4].total}</p>
                              <p className="percentage">{data?.overallStatistics?.individualPercent[4].percent}%</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </div>
            {/* end satisafaction rating box */}
            <PercentageDistButtonPresses
              className="card-xl-stretch mb-5 mb-xl-8"
              title={"Percentage distribution of button presses"}
              categories={data?.timeDistribution?.categories || []}
              series={data?.timeDistribution?.series}
            />
            {/* end percentage dist of button presses */}

            {/* begin:: device usage and fraud warning Row */}
            <div className="row">
              <div className="col-xl-6 d-flex align-items-stretch">
                <OfficeComparison className="mb-5 mb-xl-8 w-100" data={data?.overallDeviceComparisons} />
              </div>
              <div className="col-xl-6 d-flex align-items-stretch">
                <TargetSatisfaction className="mb-5 mb-xl-8 w-100" data={data?.targetDeviceSatisfaction} />
              </div>
            </div>
            {/* end:: device usage and fraud warning Row */}
          </>
        )}
      </div>
    </>
  )
}

export { DevicesPage }
