import { TextCard } from "./../shared_components/TextCard"
import React, { FC, useState, useEffect } from "react"
import cogoToast from "cogo-toast"
import ReactGA from "react-ga4"
import { useQuery } from "@tanstack/react-query"
import { CitizenExperiences, Scores } from "../../partials/widgets"
import { getInterviewData } from "../../apis/app.service"
import { LoadingSpinner } from "../shared_components/LoadingSpinner"
import { Error404 } from "../../modules/errors/components/Error404"

const d = new Date()
const monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]
let monthIndex = d.getMonth()
const defaultMonth = monthArray[monthIndex]
let defaultYear = d.getFullYear()

const InterviewsPage: FC<React.PropsWithChildren<unknown>> = () => {
  ReactGA.initialize("G-7PJYYTMLJC")
  const path = window.location.pathname + window.location.search
  const [month, setMonth] = useState<string>(defaultMonth)
  const [year, setYear] = useState<string>(`${defaultYear}`)

  const {
    data: response,
    refetch,
    isLoading,
    isError,
    isSuccess
  } = useQuery({
    queryKey: ["Interview Statistics"],
    queryFn: () => getInterviewData({ month, year }),
    refetchOnWindowFocus: false,
    onError: (error) => {
      cogoToast.error(`${error}`, {
        hideAfter: 5,
        position: "top-right"
      })
    }
  })

  const data = response?.data

  useEffect(() => {
    refetch()
  }, [month, refetch, year])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: path })
  }, [])

  return (
    <>
      <div className="">
        {/* begin::ToolBar */}
        <div className="toolbar" id="kt_toolbar">
          {/* begin::Container */}
          <div id="kt_toolbar_container">
            {/* begin::Actions */}
            <div className="d-flex align-items-end ps-8">
              {/* begin::Wrapper */}
              <div className="me-4">
                {/* begin::Menu */}
                {/* begin form body */}
                <form className="form" noValidate id="devices_data_filter">
                  <div className="w-100 d-flex flex-row justify-content-end">
                    {/* begin period */}
                    <div className="d-flex" style={{ width: "400px" }}>
                      <select
                        className="form-select form-select-solid mr-4"
                        name="month"
                        value={month}
                        disabled={isLoading}
                        onChange={(e) => {
                          setMonth(e.target.value)
                        }}
                      >
                        <option key={0} value="January">
                          January
                        </option>

                        <option key={1} value="February">
                          February
                        </option>
                        <option key={2} value="March">
                          March
                        </option>

                        <option key={3} value="April">
                          April
                        </option>

                        <option key={4} value="May">
                          May
                        </option>

                        <option key={5} value="June">
                          June
                        </option>

                        <option key={6} value="July">
                          July
                        </option>

                        <option key={7} value="August">
                          August
                        </option>

                        <option key={8} value="September">
                          September
                        </option>

                        <option key={9} value="October">
                          October
                        </option>

                        <option key={10} value="November">
                          November
                        </option>

                        <option key={11} value="December">
                          December
                        </option>
                      </select>

                      <select
                        className="form-select form-select-solid"
                        name={year}
                        value={year}
                        disabled={isLoading}
                        onChange={(e) => {
                          setYear(e.target.value)
                        }}
                      >
                        <option key={0} value="2025">
                          2025
                        </option>

                        <option key={1} value="2024">
                          2024
                        </option>

                        <option key={2} value="2023">
                          2023
                        </option>

                        <option key={3} value="2022">
                          2022
                        </option>

                        <option key={4} value="2022">
                          2022
                        </option>
                      </select>
                    </div>
                    {/* end period */}
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* end::Container */}
        </div>
        {/* end::ToolBar */}

        {/* begin::Row */}
        {isLoading ? <LoadingSpinner /> : null}
        {isSuccess && !isLoading ? (
          <div className="row g-5 g-xl-8">
            <div className="col-xl-6">
              <CitizenExperiences
                title="Branch Scores"
                className="mb-5 mb-xl-8"
                statistics={data?.statistics}
                dataSat={data?.departmentSatisfaction}
              />
            </div>
            <div className="col-xl-6">
              <Scores title="Citizen Experiences" data={data?.yesNO} className="mb-5 mb-xl-8" />
              <TextCard
                className="mb-5 mb-xl-8 w-100"
                title="Monthly Recommendation"
                body={data?.recomendation ?? ""}
              />
              <TextCard className="mb-5 mb-xl-8 w-100" title="Monthly Comment" body={data?.comment ?? ""} />
            </div>
          </div>
        ) : null}
        {isError && !isLoading ? <Error404 /> : null}
        {/* end::Row */}
      </div>
    </>
  )
}

export { InterviewsPage }
