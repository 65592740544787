import React, { useState } from "react"
import { defaultSettings, ISettings } from "./SettingsModel"

export function Settings() {
  const [data, setData] = useState<ISettings>(defaultSettings)
  const updateData = (fieldsToUpdate: Partial<ISettings>) => {
    const updatedData = { ...data, ...fieldsToUpdate }
    setData(updatedData)
  }

  return (
    <div className="card">
      {/* begin::Form*/}
      <form className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          <div className="row">
            <label className="col-xl-3"></label>
            <div className="col-lg-9 col-xl-6">
              <h5 className="fw-bold mb-6">Setup Notification:</h5>
            </div>
          </div>

          <div className="mb-5 row align-items-center mb-2">
            <label className="col-xl-3 col-lg-3 col-form-label fw-bold text-start">Email</label>
            <div className="col-lg-9 col-xl-6 d-flex align-items-center">
              <div className="form-check form-check-custom form-check-solid form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={data.setupEmailNotifications.emailNotifications}
                  onChange={() =>
                    updateData({
                      setupEmailNotifications: {
                        ...data.setupEmailNotifications,
                        emailNotifications: !data.setupEmailNotifications.emailNotifications
                      }
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="mb-5 row align-items-center">
            <label className="col-xl-3 col-lg-3 col-form-label fw-bold text-start">Password</label>
            <div className="col-lg-9 col-xl-6">
              <div className="form-check form-check-custom form-check-solid form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={data.setupEmailNotifications.sendCopyToPersonalEmail}
                  onChange={() =>
                    updateData({
                      setupEmailNotifications: {
                        ...data.setupEmailNotifications,
                        sendCopyToPersonalEmail: !data.setupEmailNotifications.sendCopyToPersonalEmail
                      }
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* end::Form*/}
    </div>
  )
}
