/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../helpers"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { APICore } from "../../../../apis/api.core"
const api = new APICore()

const ProfileHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation()
  const userData = api.getLoggedInUser()
  const profilePhotoURL = userData?.user?.profilePhoto
    ? `${userData.user.profilePhoto}`
    : `${toAbsoluteUrl("/media/avatars/blank.png")}`

  return (
    <div className="card mb-5 mb-xl-10" style={{ marginTop: "-50px" }}>
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img src={profilePhotoURL} alt="Metornic" />
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                    {userData.user.username}
                  </a>
                  <a href="#">
                    <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-1 svg-icon-primary" />
                  </a>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                    <KTSVG path="/media/icons/duotune/communication/com006.svg" className="svg-icon-4 me-1" />
                    {userData.user.organisationName}
                  </a>

                  <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                    <KTSVG path="/media/icons/duotune/communication/com011.svg" className="svg-icon-4 me-1" />
                    {userData.user.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "/crafted/pages/profile/overview" && "active")
                }
                to="/crafted/pages/profile/overview"
              >
                Overview
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === "/crafted/pages/profile/settings" && "active")
                }
                to="/crafted/pages/profile/settings"
              >
                Settings
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { ProfileHeader }
