import React, { FC, useState } from "react"
import { DatePicker, Button, Dropdown, MenuProps } from "antd"
import { DownOutlined } from "@ant-design/icons"
import moment from "moment"

const { RangePicker } = DatePicker

const DeviceDatePicker: FC<React.PropsWithChildren<any>> = ({
  selectedDate,
  setSelectedDate,
  selectedDateFormatted,
  setSelectedDateFormatted,
  type,
  setType,
  setDateQuery
}) => {
  const [visible, setVisible] = useState(false)
  const typeCapitalize = type.charAt(0).toUpperCase() + type.slice(1)

  const handleDayDateChange = (date: any, dateString: any) => {
    if (date) {
      setType("day")
      setVisible(false)
      setSelectedDateFormatted(dateString)
      setSelectedDate(date)
      const start = moment(date).startOf("day")
      const end = moment(date).endOf("day")
      const timeline = moment(date).isSame(moment(), "day") ? ["Today", "Yesterday"] : ["This day", "Past day"]
      setDateQuery({
        currentStartDate: start.format("YYYY-MM-DD HH:mm:ss"),
        currentEndDate: end.format("YYYY-MM-DD HH:mm:ss"),
        previousStartDate: start.subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"),
        previousEndDate: end.subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"),
        timeline
      })
    }
  }

  const handleWeekDateChange = (date: any, dateString: any) => {
    if (date) {
      setSelectedDateFormatted(dateString)
      setSelectedDate(date)
      setType("week")
      setVisible(false)

      const start = moment(date).startOf("week")
      const end = moment(date).endOf("week")
      setDateQuery({
        currentStartDate: start.format("YYYY-MM-DD HH:mm:ss"),
        currentEndDate: end.format("YYYY-MM-DD HH:mm:ss"),
        previousStartDate: start.subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"),
        previousEndDate: end.subtract(1, "weeks").format("YYYY-MM-DD HH:mm:ss"),
        timeline: ["This week", "Last week"]
      })
    }
  }

  const handleMonthDateChange = (date: any, dateString: any) => {
    // Check if both dates are selected
    if (date) {
      setSelectedDateFormatted(dateString)
      setSelectedDate(date)
      setType("month")
      setVisible(false)

      const start = moment(date).startOf("month")
      const end = moment(date).endOf("month")
      setDateQuery({
        currentStartDate: start.format("YYYY-MM-DD HH:mm:ss"),
        currentEndDate: end.format("YYYY-MM-DD HH:mm:ss"),
        previousStartDate: start.subtract(1, "months").format("YYYY-MM-DD HH:mm:ss"),
        previousEndDate: end.subtract(1, "months").endOf("month").format("YYYY-MM-DD HH:mm:ss"),
        timeline: ["This month", "Last month"]
      })
    }
  }

  const handleQuarterDateChange = (date: any, dateString: any) => {
    if (date) {
      setSelectedDateFormatted(dateString)
      setSelectedDate(date)
      setType("quarter")
      setVisible(false)

      const start = moment(date).startOf("quarter")
      const end = moment(date).endOf("quarter")
      setDateQuery({
        currentStartDate: start.format("YYYY-MM-DD HH:mm:ss"),
        currentEndDate: end.format("YYYY-MM-DD HH:mm:ss"),
        previousStartDate: start.subtract(1, "quarters").format("YYYY-MM-DD HH:mm:ss"),
        previousEndDate: end.subtract(1, "quarters").endOf("quarter").format("YYYY-MM-DD HH:mm:ss"),
        timeline: ["This quarter", "Last quarter"]
      })
    }
  }

  const handleYearDateChange = (date: any, dateString: any) => {
    // Check if both dates are selected
    if (date) {
      setSelectedDateFormatted(dateString)
      setSelectedDate(date)
      setType("year")
      setVisible(false)

      const start = moment(date).startOf("year")
      const end = moment(date).endOf("year")
      setDateQuery({
        currentStartDate: start.format("YYYY-MM-DD HH:mm:ss"),
        currentEndDate: end.format("YYYY-MM-DD HH:mm:ss"),
        previousStartDate: start.subtract(1, "years").format("YYYY-MM-DD HH:mm:ss"),
        previousEndDate: end.subtract(1, "years").format("YYYY-MM-DD HH:mm:ss"),
        timeline: ["This year", "Last year"]
      })
    }
  }

  const handleDateRangeChange = (dates: any, dateStrings: any) => {
    // Check if both dates are selected
    if (dates && dates[0] && dates[1]) {
      setSelectedDateFormatted(dateStrings.join(" to "))
      setSelectedDate(dates)
      setType("Custom")
      setVisible(false)

      const start = moment(dates[0]).startOf("day")
      const end = moment(dates[1]).endOf("day")
      const diff = end.diff(start, "days")
      setDateQuery({
        currentStartDate: start.format("YYYY-MM-DD HH:mm:ss"),
        currentEndDate: end.format("YYYY-MM-DD HH:mm:ss"),
        previousStartDate: start.subtract(diff + 1, "days").format("YYYY-MM-DD HH:mm:ss"),
        previousEndDate: end.subtract(diff + 1, "days").format("YYYY-MM-DD HH:mm:ss"),
        timeline: ["This period", "Last period"]
      })
    }
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="row">
          <h4 className="col-3 d-flex align-items-center">Daily:</h4>
          <div className="col-9">
            <DatePicker
              className="w-100"
              onClick={(e) => e.stopPropagation()}
              value={type === "day" ? selectedDate : null}
              onChange={handleDayDateChange}
              disabledDate={(current) => current > moment().endOf("day")}
            />
          </div>
        </div>
      )
    },

    {
      key: "2",
      label: (
        <div className="row">
          <h4 className="col-3 d-flex align-items-center">Weekly:</h4>
          <div className="col-9">
            <DatePicker
              className="w-100"
              picker="week"
              onClick={(e) => e.stopPropagation()}
              value={type === "week" ? selectedDate : null}
              onChange={handleWeekDateChange}
              disabledDate={(current) => current > moment().endOf("week")}
            />
          </div>
        </div>
      )
    },
    {
      key: "3",
      label: (
        <div className="row">
          <h4 className="col-3 d-flex align-items-center">Monthly:</h4>
          <div className="col-9">
            <DatePicker
              className="w-100"
              picker="month"
              onClick={(e) => e.stopPropagation()}
              value={type === "month" ? selectedDate : null}
              onChange={handleMonthDateChange}
              disabledDate={(current) => current > moment().endOf("month")}
            />
          </div>
        </div>
      )
    },
    {
      key: "4",
      label: (
        <div className="row">
          <h4 className="col-3 d-flex align-items-center">Quarterly:</h4>
          <div className="col-9">
            <DatePicker
              className="w-100"
              picker="quarter"
              onClick={(e) => e.stopPropagation()}
              value={type === "quarter" ? selectedDate : null}
              onChange={handleQuarterDateChange}
              disabledDate={(current) => current > moment().endOf("quarter")}
            />
          </div>
        </div>
      )
    },
    {
      key: "5",
      label: (
        <div className="row">
          <h4 className="col-3 d-flex align-items-center">Yearly:</h4>
          <div className="col-9">
            <DatePicker
              className="w-100"
              picker="year"
              onClick={(e) => e.stopPropagation()}
              value={type === "year" ? selectedDate : null}
              onChange={handleYearDateChange}
              disabledDate={(current) => current > moment().endOf("year")}
            />
          </div>
        </div>
      )
    },
    {
      key: "6",
      label: (
        <div className="row">
          <h4 className="col-3 d-flex align-items-center">Custom:</h4>
          <div className="col-9">
            <RangePicker
              className="w-100"
              onClick={(e) => e.stopPropagation()}
              value={type === "Custom" ? selectedDate : null}
              onCalendarChange={handleDateRangeChange}
              disabledDate={(current) => current > moment().endOf("day")}
            />
          </div>
        </div>
      )
    }
  ]

  return (
    <Dropdown
      menu={{ items, selectable: true }}
      trigger={["click"]}
      placement="bottomLeft"
      open={visible}
      onOpenChange={(v) => setVisible(v)}
    >
      <div
        className="d-flex align-items-center mr-4"
        style={{
          height: "43px",
          backgroundColor: "#f5f8fa",
          color: "#5e6278",
          borderColor: "#f5f8fa",
          borderRadius: "0.475rem",
          fontWeight: 500,
          fontSize: "1.1rem",
          padding: "0.75rem 1rem 0.75rem 1rem"
        }}
      >
        {typeCapitalize} : {selectedDateFormatted} <DownOutlined className="ml-4" />
      </div>
    </Dropdown>
  )
}

export { DeviceDatePicker }
