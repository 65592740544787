import ReactDOM from "react-dom/client"
// Axios
import axios from "axios"
import { Chart, registerables } from "chart.js"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

// Apps
import { MetronicI18nProvider } from "./app/i18n/Metronici18n"

import "./app/assets/sass/style.scss"
import "./app/assets/sass/style.react.scss"
import { AppRoutes } from "./app/routing/AppRoutes"
import { AuthProvider, setupAxios } from "./app/modules/auth"
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </MetronicI18nProvider>
  </QueryClientProvider>
)
