/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useRef, useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../../../helpers"
import { Formik, FormikValues, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import DatePickerComponent from "../datepicker/date-picker-component"
import FileUploadComponent from "../filepicker/file-upload-component"

interface ICreateReport {
  name: string
  type: string
  period: string
  uploadDate: string
  file: string
}

const inits: ICreateReport = {
  name: "",
  type: "",
  period: "June",
  uploadDate: "10/06/2022",
  file: ""
}

const createDeviceSchema = Yup.object({
  name: Yup.string().required().label("Report Name"),
  type: Yup.string().required().label("Type"),
  period: Yup.string().required().label("Period"),
  uploadDate: Yup.string().required().label("Upload Date"),
  file: Yup.string().label("Report File")
})

const submit = (values: ICreateReport, actions: FormikValues) => {
  return
}

const CreateReports: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <div className="modal fade" id="kt_modal_create_report" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-900px">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Create Report</h2>

            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>

          <div className="modal-body py-lg-10 px-lg-10">
            {/* start create form */}
            <div className="d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_device">
              {/* begin form body */}
              <div className="flex-row-fluid py-lg-5 px-lg-15">
                <Formik validationSchema={createDeviceSchema} initialValues={inits} onSubmit={submit}>
                  {() => (
                    <form className="form" noValidate id="kt_modal_create_device_form">
                      {/* begin form body */}
                      <div className="current" data-kt-stepper-element="content">
                        <div className="w-100">
                          {/* begin report name */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Report Name</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify Device IMEI"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              name="name"
                              placeholder=""
                            />
                            <div className="text-danger">
                              <ErrorMessage name="name" />
                            </div>
                          </div>
                          {/* end report Name */}

                          {/* begin report type */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Type</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify report Name"
                              ></i>
                            </label>

                            <Field as="select" className="form-select form-select-solid" name="type">
                              <option value="event">Daily</option>

                              <option value="permanent">Monthly</option>

                              <option value="test">Annual</option>
                            </Field>
                            <div className="text-danger">
                              <ErrorMessage name="type" />
                            </div>
                          </div>
                          {/* end report Type */}

                          {/* begin report type */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Period</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify Report period"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control form-control-lg form-control-solid"
                              name="period"
                              placeholder=""
                            />
                            <div className="text-danger">
                              <ErrorMessage name="period" />
                            </div>
                          </div>
                          {/* end report Type */}

                          {/* begin upload date */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Upload Date</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="Specify upload date"
                              ></i>
                            </label>

                            <Field
                              className="form-control form-control-lg form-control-solid"
                              name="uploadDate"
                              component={DatePickerComponent}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="uploadDate" />
                            </div>
                          </div>
                          {/* end upload date */}

                          {/* begin upload file */}
                          <div className="fv-row mb-10">
                            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                              <span className="required">Upload File</span>
                              <i
                                className="fas fa-exclamation-circle ms-2 fs-7"
                                data-bs-toggle="tooltip"
                                title="upload report file"
                              ></i>
                            </label>

                            <Field
                              className="form-control form-control-lg form-control-solid"
                              name="file"
                              component={FileUploadComponent}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="file" />
                            </div>
                          </div>
                          {/* end upload file */}
                        </div>
                      </div>

                      <div className="pt-10">
                        <div>
                          <button type="submit" className="btn btn-lg btn-primary me-3">
                            <span className="indicator-label">
                              Submit
                              <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-3 ms-2 me-0" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              {/* end form body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CreateReports }
