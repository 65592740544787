import React, {useState} from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const DatePickerComponent = () => {
  const [selectedDate, setDate] = useState(new Date())
  return (
    <DatePicker
      className='form-control form-control-lg form-control-solid'
      selected={selectedDate}
      onChange={(date: Date) => setDate(date)}
    />
  )
}

export default DatePickerComponent
