/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./core/style.css";

type Props = {
  className: string;
  title: string;
  data: Object;
};

const DeviceStats: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  title,
  data,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 new products</span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <a href='#' className='btn btn-sm btn-light-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            New Device
          </a>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bolder text-muted bg-light">
                <th className="ps-4 min-w-200px rounded-start">Device</th>
                <th className="min-w-100px text-end">Total feedback</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data !== undefined
                ? Object.entries(data).map(([key, value]) => (
                    <tr key={key}>
                      <td>
                        <a
                          href="#"
                          className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                        >
                          {key}
                        </a>
                      </td>
                      <td>
                        <a
                          href="#"
                          className={
                            value < 50
                              ? "text-danger text-end fw-bolder  d-block mb-1 fs-6"
                              : value < 80
                              ? "text-warning text-end fw-bolder  d-block mb-1 fs-6"
                              : "text-success text-end fw-bolder  d-block mb-1 fs-6"
                          }
                        >
                          {value}
                        </a>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { DeviceStats };
