/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import cogoToast from "cogo-toast"
import ReactGA from "react-ga4"
import { StatisticsWidget5 } from "../../partials/widgets"
import { useQuery } from "@tanstack/react-query"
import { DeviceStats } from "../../partials/widgets/custom/DeviceStats"
import { getDashbboardStats } from "../../apis/app.service"

import { LoadingSpinner } from "../shared_components/LoadingSpinner"
import { Error404 } from "../../modules/errors/components/Error404"

const d = new Date()
const monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]
let monthIndex = d.getMonth()
const defaultMonth = monthArray[monthIndex]
let defaultYear = d.getFullYear()

const DashboardWrapper = () => {
  ReactGA.initialize("G-7PJYYTMLJC")
  const path = window.location.pathname + window.location.search

  const [month, setMonth] = useState<string>(defaultMonth)
  const [year, setYear] = useState<string>(`${defaultYear}`)

  const {
    data: response,
    refetch,
    isFetching,
    isError,
    isSuccess
  } = useQuery({
    queryKey: ["Dashboard Statistics"],
    queryFn: () => getDashbboardStats({ month, year }),
    refetchOnWindowFocus: false,
    onError: (error) => {
      cogoToast.error(`${error}`, {
        hideAfter: 5,
        position: "top-right"
      })
    }
  })
  const data = response?.data

  useEffect(() => {
    refetch()
  }, [refetch, month, year])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: path })
  }, [])

  return (
    <>
      <div className="">
        {/* begin::ToolBar */}
        <div className="toolbar" id="kt_toolbar">
          {/* begin::Container */}
          <div id="kt_toolbar_container">
            {/* begin::Actions */}
            <div className="d-flex align-items-end ps-8">
              {/* begin::Wrapper */}
              <div className="me-4">
                {/* begin::Menu */}
                {/* begin form body */}
                <form className="form" noValidate id="devices_data_filter">
                  <div className="w-100 d-flex flex-row justify-content-end">
                    <div className="d-flex" style={{ width: "400px" }}>
                      <select
                        className="form-select form-select-solid mr-4"
                        name="month"
                        value={month}
                        onChange={(e) => {
                          setMonth(e.target.value)
                        }}
                      >
                        <option key={0} value="January">
                          January
                        </option>

                        <option key={1} value="February">
                          February
                        </option>
                        <option key={2} value="March">
                          March
                        </option>

                        <option key={3} value="April">
                          April
                        </option>

                        <option key={4} value="May">
                          May
                        </option>

                        <option key={5} value="June">
                          June
                        </option>

                        <option key={5} value="July">
                          July
                        </option>

                        <option key={5} value="August">
                          August
                        </option>

                        <option key={5} value="September">
                          September
                        </option>

                        <option key={5} value="October">
                          October
                        </option>

                        <option key={5} value="November">
                          November
                        </option>

                        <option key={5} value="December">
                          December
                        </option>
                      </select>

                      <select
                        className="form-select form-select-solid"
                        name={year}
                        value={year}
                        onChange={(e) => {
                          setYear(e.target.value)
                        }}
                      >
                        <option key={0} value="2025">
                          2025
                        </option>

                        <option key={1} value="2024">
                          2024
                        </option>

                        <option key={2} value="2023">
                          2023
                        </option>

                        <option key={3} value="2022">
                          2022
                        </option>

                        <option key={4} value="2022">
                          2022
                        </option>
                      </select>
                    </div>
                    {/* end period */}
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* end::Container */}
        </div>
        {/* end::ToolBar */}
        {isFetching ? <LoadingSpinner /> : null}
        {isSuccess && !isFetching ? (
          <>
            {/* begin::Row */}
            <div className="row g-5 g-xl-8">
              <div className="col-xl-3">
                <StatisticsWidget5
                  className="card-xl-stretch mb-xl-8"
                  svgIcon="/media/icons/duotune/graphs/gra001.svg"
                  color="white"
                  iconColor="dark"
                  title={
                    data?.OverallCardStarts?.satisfaction !== undefined ? data?.OverallCardStarts?.satisfaction : "0"
                  }
                  description="Average Satisfaction Rating"
                />
              </div>
              <div className="col-xl-3">
                <StatisticsWidget5
                  className="card-xl-stretch mb-xl-8"
                  svgIcon="/media/icons/home/avarage-waiting-time.svg"
                  color="dark"
                  iconColor="white"
                  title={
                    data?.OverallCardStarts?.waitingTime !== undefined ? data?.OverallCardStarts?.waitingTime : "0"
                  }
                  description="Average Waiting Time"
                />
              </div>
              <div className="col-xl-3">
                <StatisticsWidget5
                  className="card-xl-stretch mb-xl-8"
                  svgIcon="/media/icons/duotune/communication/com014.svg"
                  color="warning"
                  iconColor="white"
                  title={data?.OverallCardStarts?.count !== undefined ? data?.OverallCardStarts?.count : "0"}
                  description="Interviewed Citizens"
                />
              </div>
              <div className="col-xl-3">
                <StatisticsWidget5
                  className="card-xl-stretch mb-5 mb-xl-8"
                  svgIcon="/media/icons/duotune/technology/teh001.svg"
                  color="secondary"
                  iconColor="dark"
                  title={
                    data?.OverallCardStarts?.devicePresses !== undefined
                      ? data?.OverallCardStarts?.devicePresses.toString()
                      : "0"
                  }
                  description="Device Presses"
                />
              </div>
            </div>
            {/* end::Row */}
            {/* begin::Row */}
            <div className="row g-5 g-xl-8">
              {/* <div className="col-xl-6 d-flex align-items-stretch">
                <CitizenConfidence data={data?.officeRanking} className="mb-5 mb-xl-8 w-100" />
              </div> */}
              <div className="col-xl-12 d-flex align-items-stretch">
                <DeviceStats data={data?.deviceStats} title="Device Statistics" className="mb-5 mb-xl-8 w-100" />
              </div>
            </div>
            {/* end::Row */}
          </>
        ) : null}
        {isError && !isFetching ? <Error404 /> : null}
      </div>
    </>
  )
}

export { DashboardWrapper }
