import React, { FC, useEffect, useRef, useState } from "react"
import { KTSVG, toAbsoluteUrl } from "../../helpers"
// import {ToastContainer, toast} from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'

type Props = {
  item?: string
  item_id?: string
}

const ConfirmDelete: FC<React.PropsWithChildren<Props>> = (props) => {
  const { item, item_id } = props

  const handleDelete = () => {
    // toast('Succesfully deleted ' + item)
  }

  return (
    <>
      <div className="modal fade" id="kt_modal_confirm_delete" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-900px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Confirm Action</h2>

              <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
              </div>
            </div>

            <div className="modal-body py-lg-5 px-lg-5">Are you sure you want to delete {item}?</div>
            <div className="modal-footer">
              <button onClick={handleDelete} data-bs-dismiss="modal" className="btn btn-lg btn-danger me-3">
                <span className="indicator-label">
                  Delete
                  <KTSVG path="/media/icons/duotune/general/gen034.svg" className="svg-icon-3 ms-2 me-0" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default ConfirmDelete
