/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react"
import { Link } from "react-router-dom"
import { getAuth } from "../../../../app/apis/app.service"
import { APICore } from "../../../apis/api.core"

const api = new APICore()

const HeaderUserMenu: FC<React.PropsWithChildren<unknown>> = () => {
  const userData = api.getLoggedInUser()
  const profilePhotoURL = userData?.user?.profilePhoto
    ? `${userData.user.profilePhoto}`
    : `https://eu.ui-avatars.com/api/?name=${userData?.user?.name}&size=250`
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-375px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img src={profilePhotoURL} alt="profile pic" />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">{userData?.user?.name}</div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {userData?.user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5 my-3">
        <Link to={"/crafted/pages/profile"} className="menu-link px-5">
          My Profile
        </Link>
      </div>

      {/* <div className="menu-item px-5 my-3">
        <Link to="/crafted/account/settings" className="menu-link px-5">
          Account Settings
        </Link>
      </div> */}

      <div className="menu-item px-5">
        <a onClick={() => getAuth({ type: "LOGOUT" })} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
