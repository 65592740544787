import React, { useState } from "react"
import { toAbsoluteUrl } from "../../../../helpers"
import { IProfileDetails } from "../views/overview/OverviewModel"
import { useMutation } from "@tanstack/react-query"
import cogoToast from "cogo-toast"
import * as Yup from "yup"
import { Formik, Form, ErrorMessage } from "formik"
import type { UploadProps } from "antd"
import { Button, message, Upload } from "antd"
import { APICore } from "../../../../apis/api.core"
import { updateProfile } from "../../../../apis/app.service"

const api = new APICore()

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required("First name is required"),
  lName: Yup.string().required("Last name is required"),
  username: Yup.string().required("Username  is required"),
  organisationName: Yup.string().required("Organisation name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().required("Phone is required")
})

const ProfileDetails: React.FC<React.PropsWithChildren<unknown>> = () => {
  const userData = api.getLoggedInUser()
  const [uploadingPhoto, setUploadingPhoto] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<IProfileDetails>({
    fName: userData.user.name.split(" ")[0],
    lName: userData.user.name.split(" ")[1],
    username: userData.user.username,
    name: userData.user.name,
    organisationName: userData.user.organisationName,
    email: userData.user.email,
    phoneNumber: userData.user.phoneNumber,
    profilePhoto: userData.user.profilePhoto
  })
  const profilePhotoURL = userData.user.profilePhoto
    ? `url(${initialValues.profilePhoto})`
    : `url(${toAbsoluteUrl("/media/avatars/blank.png")})`

  const mutation = useMutation({
    mutationFn: (body: IProfileDetails) => updateProfile(body),
    onSuccess: ({ message, data }) => {
      cogoToast.success(message, { hideAfter: 3, position: "top-right" })
      api.updateLoggedInUserProfileData(data)
    },
    onError(error, variables, context) {
      cogoToast.error(`${error}`, {
        hideAfter: 3,
        position: "top-right"
      })
    }
  })

  const props: UploadProps = {
    name: "profilePhoto",
    accept: ".jpg, .jpeg, .png",
    method: "PATCH",
    action: "http://localhost:8080/api/user/updateProfilePhoto",
    headers: {
      authorization: `Bearer ${userData.token}`
    },
    onChange(info) {
      if (info.file.status === "uploading") {
        setUploadingPhoto(true)
      }
      if (info.file.status === "done") {
        api.updateLoggedInUserProfileData(info.file.response.data)
        setInitialValues((prev) => {
          return { ...prev, profilePhoto: info.file.response.data.profilePhoto }
        })
        cogoToast.success(`${info.file.name} file uploaded successfully`, {
          hideAfter: 3,
          position: "top-right"
        })
        setUploadingPhoto(false)
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else if (info.file.status === "error") {
        cogoToast.error(`${info.file.name} file upload failed.`, {
          hideAfter: 3,
          position: "top-right"
        })
      }
    },
    showUploadList: false
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_profile_details"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Profile Details</h3>
        </div>
      </div>

      <div id="kt_account_profile_details" className="collapse show">
        <Formik
          validationSchema={profileDetailsSchema}
          initialValues={initialValues}
          onSubmit={async (values) => {
            values.name = `${values.fName} ${values.lName}`
            mutation.mutate(values)
          }}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                  <div className="col-lg-8">
                    <div className="image-input image-input-outline" data-kt-image-input="true">
                      <Upload {...props}>
                        <div
                          className="image-input-wrapper w-125px h-125px"
                          style={{ backgroundImage: profilePhotoURL, cursor: "pointer" }}
                        ></div>
                      </Upload>
                    </div>
                    {uploadingPhoto && (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>
                </div>
                <hr style={{ backgroundColor: "#000" }} />

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Full Name</label>

                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 fv-row mb-sm-3">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="fName"
                          onChange={formik.handleChange}
                          value={formik.values.fName}
                          placeholder="First name"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="fName" />
                        </div>
                      </div>

                      <div className="col-lg-6 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          name="lName"
                          onChange={formik.handleChange}
                          value={formik.values.lName}
                          placeholder="Last name"
                        />
                        <div className="text-danger">
                          <ErrorMessage name="lName" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">User Name</label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="username"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      placeholder="User name"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="username" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Organisation Name</label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="organisationName"
                      onChange={formik.handleChange}
                      value={formik.values.organisationName}
                      placeholder="Organisation Name"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="organisationName" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span className="required">Email</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="email"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <div className="text-danger">
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                </div>

                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    <span className="required">Phone</span>
                  </label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      placeholder="+256700000000"
                    />
                    <div className="text-danger">
                      <ErrorMessage name="phoneNumber" />
                    </div>
                  </div>
                </div>

                {/* <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">Communication</label>

                  <div className="col-lg-8 fv-row">
                    <div className="d-flex align-items-center mt-3">
                      <label className="form-check form-check-inline form-check-solid me-5">
                        <input
                          className="form-check-input"
                          name="communication[]"
                          type="checkbox"
                          defaultChecked={false}
                          onChange={() => {}}
                        />
                        <span className="fw-bold ps-2 fs-6">Email</span>
                      </label>

                      <label className="form-check form-check-inline form-check-solid">
                        <input
                          className="form-check-input"
                          name="communication[]"
                          type="checkbox"
                          defaultChecked={false}
                          onChange={() => {}}
                        />
                        <span className="fw-bold ps-2 fs-6">Phone</span>
                      </label>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="d-flex justify-content-end py-6 px-9">
                <button type="submit" className="btn btn-primary" disabled={mutation.isLoading}>
                  {mutation.isLoading && (
                    <>
                      <div className="spinner-border spinner-border-sm mr-2" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </>
                  )}
                  SAVE
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export { ProfileDetails }
