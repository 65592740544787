import { Outlet } from "react-router-dom"
import { AsideDefault } from "./components/aside/AsideDefault"
import { Footer } from "./components/Footer"
import { HeaderWrapper } from "./components/header/HeaderWrapper"
import { Content } from "./components/Content"
import { PageDataProvider } from "./core"

const MasterLayout = () => {
  return (
    <PageDataProvider>
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        <div className="wrapper d-flex flex-column flex-row-fluid" style={{ background: "#f5f5f5" }}>
          <HeaderWrapper />

          <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
            {/* <Toolbar /> */}
            <div className="post d-flex flex-column-fluid" id="kt_post" style={{ zIndex: 1 }}>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </PageDataProvider>
  )
}

export { MasterLayout }
