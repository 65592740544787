/* eslint-disable jsx-a11y/anchor-is-valid */
import { toAbsoluteUrl } from "../../helpers"
import * as Yup from "yup"
import clsx from "clsx"
import { useFormik } from "formik"
import cogoToast from "cogo-toast"
import { useMutation } from "@tanstack/react-query"
import { getAuth } from "../../apis/app.service"
import { AuthAction, AuthBody } from "../../apis/models"

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required("Password is required")
})

const initialValues = {
  email: "",
  password: ""
}

const LoginPage = () => {
  const mutation = useMutation({
    mutationFn: (body: AuthAction) => getAuth(body),
    onSuccess: (data) => {
      cogoToast.success("Login successfully", { hideAfter: 3, position: "top-right" })
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    },
    onError(error, variables, context) {
      cogoToast.error(`${error}`, {
        hideAfter: 3,
        position: "top-right"
      })
    }
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values: AuthBody) => {
      const authAction: AuthAction = {
        type: "LOGIN",
        body: values
      }
      mutation.mutate(authAction)
    }
  })

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain"
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/svg/backgrounds/login-bg.svg")})`
      }}
    >
      {/* begin::Content */}
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl("/media/logos/favicon.svg")} className="h-45px" />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
          <form className="form w-100" onSubmit={formik.handleSubmit} noValidate>
            {/* begin::Heading */}
            <div className="text-center mb-10">
              <h1 className="h1 mb-3">Welcome Back</h1>
              <div className="text-gray-400 fw-bold fs-4">Sign in to your sema account</div>
            </div>
            {/* begin::Heading */}

            {formik.status ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            ) : null}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">Email</label>
              <input
                placeholder="Email"
                {...formik.getFieldProps("email")}
                className={clsx(
                  "form-control form-control-lg form-control-solid mb-2",
                  { "is-invalid": formik.touched.email && formik.errors.email },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email
                  }
                )}
                type="email"
                name="email"
                autoComplete="off"
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container text-danger">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}
            <div className="h-20px"></div>

            {/* begin::Form group */}
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  {/* begin::Label */}
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                  {/* end::Label */}
                  {/* begin::Link */}
                </div>
              </div>
              <input
                type="password"
                autoComplete="off"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control form-control-lg form-control-solid mt-2",
                  {
                    "is-invalid": formik.touched.password && formik.errors.password
                  },
                  {
                    "is-valid": formik.touched.password && !formik.errors.password
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container text-danger">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            {/* <div className="text-center"> */}
            <button
              type="submit"
              className="btn btn-lg btn-primary w-100 mb-5 text-capitalize"
              disabled={formik.isSubmitting || !formik.isValid}
              style={{
                boxShadow: "7px 10px 10px -11px rgba(87,85,87,1)",
                borderWidth: 0,
                color: "white",
                fontWeight: "bolder",
                fontSize: "14px"
              }}
            >
              {!mutation.isLoading && <span className="indicator-label">Continue</span>}
              {mutation.isLoading && (
                <span className="indicator-progress" style={{ display: "block" }}>
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            {/* </div> */}
            {/* end::Action */}
          </form>
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}

export { LoginPage }
