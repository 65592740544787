import { APICore } from "./api.core"
import { AuthAction, FiltersRequestBody } from "./models"
import { IProfileDetails, IPasswordDetails } from "../pages/profile/components/views/overview/OverviewModel"

const AUTH_LOCAL_KEY = "talktosema_user"
const api = new APICore()
const getAuth = async (authAction: AuthAction) => {
  switch (authAction.type) {
    case "LOGIN":
      const response = await api.postAPI("/api/user/login", authAction.body)

      const { status, message } = response
      if (status !== "success") {
        throw new Error(message)
      }

      localStorage.setItem(AUTH_LOCAL_KEY, response?.data !== undefined ? JSON.stringify(response?.data) : "")
      return response?.data

    case "LOGOUT":
      localStorage.removeItem(AUTH_LOCAL_KEY)

      window.location.reload()
      break

    default:
      break
  }
}

const getInterviewData = async (body?: FiltersRequestBody) => {
  const response = await api.postAPI("/api/statistic/interviewStats", body)

  const { status, message } = response
  if (status !== "success") {
    throw new Error(message)
  }

  return response
}

const getReportData = async () => {
  const response = await api.getAPI("/api/report", { params: { pageIndex: 1, pageLimit: 100 } })

  const { status, message } = response
  if (status !== "success") {
    throw new Error(message)
  }

  return response
}

const getDashbboardStats = async (body?: FiltersRequestBody) => {
  const response = await api.postAPI("/api/statistic/dashboardStats", body)

  const { status, message } = response
  if (status !== "success") {
    throw new Error(message)
  }

  return response
}

const getDeviceStats = async (query?: FiltersRequestBody) => {
  const response = await api.getAPI("/api/statistic/deviceStats", { params: { ...query } })

  const { status, message } = response
  if (status !== "success") {
    throw new Error(message)
  }

  return response
}

const getFilters = async () => {
  const response = await api.getAPI("/api/statistic/deviceFilters")

  const { status, message } = response
  if (status !== "success") {
    throw new Error(message)
  }

  return response
}

const updateProfile = async (body: IProfileDetails) => {
  const response = await api.patchAPI("/api/user/updateProfile", body)

  const { status, message } = response
  if (status !== "success") {
    throw new Error(message)
  }

  return response
}

const updatePassword = async (body: IPasswordDetails) => {
  const response = await api.patchAPI("/api/user/userUpdatePassword", body)

  const { status, message } = response
  if (status !== "success") {
    throw new Error(message)
  }

  return response
}

export {
  AUTH_LOCAL_KEY,
  getInterviewData,
  getReportData,
  getAuth,
  getDashbboardStats,
  getDeviceStats,
  getFilters,
  updateProfile,
  updatePassword
}
