import React, { useState } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import { KTSVG } from "../../../../helpers"
import { withStyles } from "@material-ui/core/styles"

const defaultToolbarStyles = {
  iconButton: {}
}

const CustomToolbar = () => {
  return (
    <React.Fragment>
      <Tooltip title={"custom button"}>
        {/* begin::Add user */}
        <button
          type="button"
          className="py-2 px-4 btn-primary"
          style={{
            borderWidth: 0,
            borderRadius: 5,
            color: "white",
            fontWeight: "bolder"
          }}
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_create_report"
        >
          <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2 text-white" />
          Add Report
        </button>
        {/* end::Add user */}
      </Tooltip>
    </React.Fragment>
  )
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar)
