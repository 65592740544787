import { CardHeader } from "@material-ui/core";
import React, { FC } from "react";
import { Card } from "react-bootstrap";

type Props = {
  className: string;
  title: string;
  body: string;
};

export const TextCard: FC<React.PropsWithChildren<Props>> = ({
  className,
  title,
  body,
}) => {
  return (
    <>
      <Card className={className}>
        <Card.Header>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text>{body}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};
