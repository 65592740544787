import { Navigate, Routes, Route, Outlet } from "react-router-dom"
import { PageLink, PageTitle } from "../../layout/core"
import { Overview } from "./components/views/overview/Overview"

import { Settings } from "./components/views/settings/Settings"

import { ProfileHeader } from "./components/views/ProfileHeader"

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/crafted/pages/profile/overview",
    isSeparator: false,
    isActive: false
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false
  }
]

const ProfilePage = () => (
  <Routes>
    <Route
      element={
        <>
          <ProfileHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path="overview"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
            <Overview />
          </>
        }
      />
      <Route
        path="settings"
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Settings</PageTitle>
            <Settings />
          </>
        }
      />

      <Route index element={<Navigate to="/crafted/pages/profile/overview" />} />
    </Route>
  </Routes>
)

export default ProfilePage
