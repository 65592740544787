/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import GaugeChart from "react-gauge-chart";
import { TargetDeviceSatisfaction } from "../../../../app/apis/models";
type Props = {
  className: string;
  data: TargetDeviceSatisfaction| undefined;
};

const TargetSatisfaction: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  data
}) => {


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">
            Target Satisfaction Rate
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {/* begin::Chart */}
        <div className="d-flex justify-content-start">
          <GaugeChart
            id="gauge-chart3"
            nrOfLevels={5}
            colors={["#DB2524", "#EA8C28", "#CDCF00", "#7EB147", "#00803D"]}
            arcWidth={0.3}
            percent={data?.averageSatisfaction}
            textColor="#000"
            style={{ transform: "scale(1.3)"}}
          />
        </div>
        
        <h4 className="d-flex justify-content-center">
          {data?.rating}
        </h4>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { TargetSatisfaction };
