/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/homepage"
        icon="/media/icons/duotune/general/gen001.svg"
        title="Homepage"
        fontIcon=""
      />
      <AsideMenuItem
        to="/interviews"
        icon="/media/icons/duotune/abstract/abs007.svg"
        title="Interviews"
        fontIcon=""
      />
      <AsideMenuItem
        to="/devices"
        icon="/media/icons/duotune/abstract/abs006.svg"
        title="Devices"
        fontIcon=""
      />
      <AsideMenuItem
        to="/reports"
        icon="/media/icons/duotune/files/fil003.svg"
        title="Reports"
        fontIcon=""
      />
    </>
  );
}
