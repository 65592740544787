import React, { FC } from "react"
import { useIntl } from "react-intl"
import { PageTitle } from "../../layout/core"
import {
  StatisticsWidget1,
  StatisticsWidget2,
  StatisticsWidget3,
  StatisticsWidget4,
  StatisticsWidget5,
  StatisticsWidget6
} from "../../partials/widgets"

const WidgetsPage__: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      {/* begin::Row */}
      <div className="row gy-5 g-xl-8">
        {/* <div className='col-xxl-4'>
        <StatisticsWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div> */}
        <div className="col-xxl-4">
          <StatisticsWidget1
            className="card-xxl-stretch"
            image={""}
            title={"StatisticsWidget1"}
            time={""}
            description={"statistics 1 widget"}
          />
        </div>
        <div className="col-xxl-4">
          <StatisticsWidget2
            className="card-xxl-stretch"
            title={"StatisticsWidget2"}
            description={"statistics widget 2"}
            avatar={""}
          />
        </div>
        <div className="col-xxl-4">
          <StatisticsWidget3
            className="card-xxl-stretch"
            title={"StatisticsWidget3"}
            description={"statswidget 3"}
            change={""}
            color="success"
          />
        </div>
        <div className="col-xxl-4">
          <StatisticsWidget4 className="card-xxl-stretch" svgIcon={""} color="success" change={""} description={""} />
        </div>
        <div className="col-xxl-4">
          <StatisticsWidget4
            className="card-xxl-stretch"
            svgIcon={""}
            color="success"
            change={""}
            description={"StatisticsWidget4"}
          />
        </div>
        <div className="col-xxl-4">
          <StatisticsWidget5
            className="card-xxl-stretch"
            color="success"
            svgIcon={""}
            iconColor={""}
            title={"StatisticsWidget5"}
            description={"statistics widget 5"}
          />
        </div>
        <div className="col-xxl-4">
          <StatisticsWidget6
            className="card-xxl-stretch"
            color="success"
            title={"StatisticsWidget6"}
            description={"statistics widget 6"}
            progress={""}
          />
        </div>
        <div className="col-xxl-4">{/* <StatisticsWidget7 className='card-xxl-stretch' /> */}</div>
        <div className="row g-5 gx-xxl-8">
          <div className="col-xxl-4">{/* <StatisticsWidget8 className='card-xxl-stretch mb-xl-3' /> */}</div>
          <div className="col-xxl-4">{/* <StatisticsWidget9 className='card-xxl-stretch mb-xl-3' /> */}</div>
        </div>
        <div className="col-xxl-4">
          {/* <StatisticsWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
          <StatisticsWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
          /> */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> */}
      {/* end::Row */}
    </>
  )
}

const WidgetsPage_: FC<React.PropsWithChildren<unknown>> = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "MENU.DASHBOARD" })}</PageTitle>
      <WidgetsPage__ />
    </>
  )
}

// export {WidgetsPage_}
export { WidgetsPage_ }
