import { lazy, FC, Suspense } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import { MasterLayout } from "../layout/MasterLayout"
import TopBarProgress from "react-topbar-progress-indicator"
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper"
import { DevicesPage } from "../pages/devices/DevicesPage"
import { InterviewsPage } from "../pages/interviews/InterviewsPage"
import { ReportsPage } from "../pages/reports/ReportsPage"
import ProfilePage from "../pages/profile/ProfilePage"
import { WidgetsPage_ } from "../pages/widgets/WidgetsPage"
import { MenuTestPage } from "../pages/MenuTestPage"
import { getCSSVariableValue } from "../assets/ts/_utils"

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import("../pages/layout-builder/BuilderPageWrapper"))
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"))
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"))
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"))
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/homepage" />} />
        {/* Pages */}
        <Route path="homepage" element={<DashboardWrapper />} />
        <Route path="interviews" element={<InterviewsPage />} />
        <Route path="devices" element={<DevicesPage />} />
        <Route path="reports" element={<ReportsPage />} />
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <Route path="widgets" element={<WidgetsPage_ />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route path="apps/user-management/*" element={<SuspensedView>{/* <UsersPage /> */}</SuspensedView>} />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary")
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
