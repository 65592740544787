import React from "react";
import { MenuItem } from "./MenuItem";
import { useIntl } from "react-intl";

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      <MenuItem title="Homepage" to="/homepage" />
      <MenuItem title="Interviews" to="/interviews" />
      <MenuItem title="Devices" to="/devices" />
      <MenuItem title="Reports" to="/reports" />
    </>
  );
}
