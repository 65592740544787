import { FC } from "react"

const Error404: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <h1 className="fw-bolder fs-4x text-gray-700 mb-10">Data Not Found</h1>

      <div className="fw-bold fs-3 text-gray-400 mb-15">The data you looked for is not found for specified period</div>
    </>
  )
}

export { Error404 }
