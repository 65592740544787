import axios, { AxiosRequestConfig } from "axios"

export const AUTH_LOCAL_KEY: string = "talktosema_user"
// content type
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
})

instance.interceptors.request.use((request) => {
  const loggedInUser = getUserFromCookie()
  if (loggedInUser) {
    request.headers.Authorization = `Bearer ${loggedInUser.token}`
  }

  return request
})
// intercepting to capture errors
instance.interceptors.response.use(
  (response): { status: string; message: string; data: any } => {
    return {
      status: response.data.status,
      message: response.data.message,
      data: response.data ? response.data.data : null
    }
  },
  (error): { status: string; message: string; data: any } => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message
    // Happens when axios fails due to cors errors or axios fails to reach server due to internet problems
    if (error.message === "Network Error" || !error.response) {
      message = navigator.onLine
        ? "Server offline! Please contact your service provider"
        : "Network Error! Please check your internet connection"
      return {
        status: "fail",
        message,
        data: null
      }
    }

    switch (error.response.status) {
      case 400:
        message = "Bad Request"
        break
      case 401:
        message = "Invalid credentials! Please log in again"
        break
      case 403:
        message = "Access Forbidden"
        break
      case 404:
        message = "Sorry! the data you are looking for could not be found"
        break
      default: {
        message = error.response && error.response.data ? error.response.data["message"] : error.message || error
      }
    }
    if (error.response.status === 401) {
      localStorage.removeItem(AUTH_LOCAL_KEY)
      window.location.reload()
    }
    return {
      status: error.response.data.status,
      message: error.response.data.message ? error.response.data.message : message,
      data: error.response ? error.response : null
    }
  }
)

const getUserFromCookie = () => {
  const user = localStorage.getItem(AUTH_LOCAL_KEY)
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null
}

class APICore {
  /**
   * Fetches data from given url
   */
  getAPI = (url: string, params?: AxiosRequestConfig): Promise<{ status: string; message: string; data: any }> => {
    return instance.get(`${url}`, params)
  }

  getFileAPI = (url: string, params: any) => {
    var queryString = params
      ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
      : ""
    return instance.get(`${url}?${queryString}`, { responseType: "blob" })
  }

  /**
   * post given data to url
   */
  postAPI = (url: string, data: unknown): Promise<{ status: string; message: string; data: any }> => {
    return instance.post(url, data)
  }

  /**
   * post given data to url with file
   */
  postAPIWithFile = (url: string, data: any) => {
    const formData = new FormData()
    for (const k in data) {
      formData.append(k, data[k])
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    }
    return instance.post(url, formData, config)
  }

  /**
   * Updates patch data
   */
  patchAPI = (url: string, data: unknown): Promise<{ status: string; message: string; data: any }> => {
    return instance.patch(url, data)
  }

  /**
   * Updates data
   */
  putAPI = (url: string, data: unknown): Promise<{ status: string; message: string; data: any }> => {
    return instance.put(url, data)
  }

  /**
   * Deletes data
   */
  deleteAPI = (url: string, params: AxiosRequestConfig): Promise<{ status: string; message: string; data: any }> => {
    return instance.delete(url, params)
  }

  isUserAuthenticated = () => {
    // 2 Get logged in user for checking if routes are protected
    return !!getUserFromCookie()
  }

  updateLoggedInUserProfileData = (newUser: any) => {
    const userData = getUserFromCookie()
    const update = { ...userData, user: newUser }
    localStorage.setItem(AUTH_LOCAL_KEY, JSON.stringify(update))
  }

  setLoggedInUser = (session: any) => {
    if (session) sessionStorage.setItem(AUTH_LOCAL_KEY, JSON.stringify(session))
    else {
      sessionStorage.removeItem(AUTH_LOCAL_KEY)
    }
  }

  setUserKey = (key: string, value: string) => {
    sessionStorage.setItem(`${AUTH_LOCAL_KEY}_${key}`, value)
  }

  getUserKey = (key: string) => {
    return sessionStorage.getItem(`${AUTH_LOCAL_KEY}_${key}`)
  }

  removeUserKey = (key: string) => {
    sessionStorage.removeItem(`${AUTH_LOCAL_KEY}_${key}`)
  }

  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie()
  }
}

export { APICore }
