import React, { FC } from "react";
import ReportsTableComponent from "./ReportsDataTable";
import { CreateReports } from "./components/forms/create-reports";

const ReportsPage: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <ReportsTableComponent />
      <CreateReports />
    </>
  );
};

export { ReportsPage };
