/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react"
import ReactGA from "react-ga4"
import { KTSVG } from "../../helpers"
import MUIDataTable from "mui-datatables"
import cogoToast from "cogo-toast"
import { useQuery } from "@tanstack/react-query"
import moment from "moment"

import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import CustomToolbar from "./components/toolbar/custom-tb"
import ConfirmDelete from "../shared_components/confirmDelete"
import { getReportData } from "../../apis/app.service"
import { LoadingSpinner } from "../shared_components/LoadingSpinner"
import { PdfViewer } from "./components/pdfviewer/PdfViewer"

const muiCache = createCache({
  key: "mui",
  prepend: true
})

const ReportsTableComponent = () => {
  ReactGA.initialize("G-7PJYYTMLJC")
  const path = window.location.pathname + window.location.search
  const [showPdf, setShowPdf] = useState(false)
  const [pdf, setPdf] = useState<string>("")

  const { data: response, isFetching } = useQuery({
    queryKey: ["Reports"],
    queryFn: () => getReportData(),
    refetchOnWindowFocus: false,
    onError: (error) => {
      cogoToast.error(`${error}`, {
        hideAfter: 5,
        position: "top-right"
      })
    }
  })
  const data = response?.data?.docs

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: path })
  }, [])

  const downloadFile = (downloadLink: string, name: string) => {
    fetch(downloadLink, {
      method: "GET"
    }).then((response) => {
      response.blob().then((blob) => {
        console.log(`response-BLOB: ${blob.text}`)
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement("a")
        a.href = url
        a.download = `${name}.pdf`
        a.click()
      })
    })
  }

  const columns = [
    {
      name: "name",
      label: "Report Name",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "year",
      label: "Year",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "createdAt",
      label: "Upload Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
          return <div className="">{moment(value).format("DD/MM/YYYY")}</div>
        }
      }
    },
    {
      name: "",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <>
              {/* begin::Menu */}
              <div className=" d-flex flex-row align-content-center w-125px py-4" data-kt-menu="true">
                {/* begin::Menu item */}
                <a
                  className=" pe-5"
                  onClick={() => {
                    setPdf(tableMeta.tableData[tableMeta.rowIndex].download_link)
                    setShowPdf(true)
                  }}
                >
                  <i className="fas fa-eye" title="View"></i>
                </a>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <a
                  className=""
                  onClick={() =>
                    downloadFile(
                      tableMeta.tableData[tableMeta.rowIndex].download_link,
                      tableMeta.tableData[tableMeta.rowIndex].name
                    )
                  }
                >
                  <i className="fas fa-download" title="View"></i>
                </a>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          )
        }
      }
    }
  ]

  const options = {
    filterType: "dropdown",
    viewColumns: false,
    responsive: "standard",
    jumpToPage: true,
    downloadOptions: { filename: "sema_reports.csv", separator: "," },
    elevation: 1,
    customToolbar: () => {
      return <CustomToolbar />
    },
    customSearchRender: (searchText: any, handleSearch: any, hideSearch: any, options: any) => {
      return (
        <div className="card-title">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG path="/media/icons/duotune/general/gen021.svg" className="svg-icon-1 position-absolute ms-6" />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14 mt-3"
              placeholder="Search Report"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
      )
    }
  }

  return (
    <>
      {/* begin pdfviewer modal */}
      <PdfViewer pdf={pdf} onCancel={() => setShowPdf(false)} visible={showPdf} />
      {/* end pdfviewer modal */}

      {/* begin::ToolBar */}
      <div className="toolbar" id="kt_toolbar">
        {/* begin::Container */}
        <div id="kt_toolbar_container">
          {/* begin::Actions */}
          <div className="d-flex align-items-end ps-8">
            {/* begin::Wrapper */}
            <div className="me-4">
              {/* begin::Menu */}
              {/* begin form body */}
            </div>
          </div>
        </div>
        {/* end::Container */}
      </div>
      {/* end::ToolBar */}
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <CacheProvider value={muiCache}>
          {/* <ThemeProvider> */}
          <MUIDataTable
            title={"Reports"}
            data={data}
            columns={columns}
            // options={options}
          />
          <ConfirmDelete />
          {/* </ThemeProvider> */}
        </CacheProvider>
      )}
    </>
  )
}

export default ReportsTableComponent
