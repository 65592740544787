import clsx from "clsx"
import React, { FC } from "react"

import { KTSVG, toAbsoluteUrl } from "../../../helpers"
import { HeaderNotificationsMenu, HeaderUserMenu, QuickLinks, Search } from "../../../partials"
import { useLayout } from "../../core"

const AUTH_LOCAL_KEY = "talktosema_user"
const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "svg-icon-1"

const Topbar: FC<React.PropsWithChildren<unknown>> = () => {
  const { config } = useLayout()
  const userData = JSON.parse(localStorage.getItem(AUTH_LOCAL_KEY)!)

  const profilePhotoURL = userData?.user?.profilePhoto
    ? `${userData.user.profilePhoto}`
    : `https://eu.ui-avatars.com/api/?name=${userData?.user?.name}&size=250`

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      {/* NOTIFICATIONS */}
      <div className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx("btn btn-icon btn-active-light-primary btn-custom", toolbarButtonHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <KTSVG path="/media/icons/duotune/general/gen022.svg" className={toolbarButtonIconSizeClass} />
        </div>
        <HeaderNotificationsMenu />
        {/* end::Menu wrapper */}
      </div>

      {/* begin::User */}
      <div className={clsx("d-flex align-items-center", toolbarButtonMarginClass)} id="kt_header_user_menu_toggle">
        {/* begin::Toggle */}
        <div
          className={clsx("cursor-pointer symbol", toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <img src={profilePhotoURL} alt="profile pic" />
          {/* <img
            src={toAbsoluteUrl("/media/logos/sema-logo.png")}
            alt="profile pic"
          /> */}
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export { Topbar }
